import ApplicationController from 'modules/application_controller';
import formatTimeString from 'plugins/number/format_time_string';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class extends ApplicationController {
  static get targets() {
    return [
      'elapsed',
      'projectSelect',
      'eventRoleSelect',
      'startedAt',
      'startedAtSpinner',
      'note',
      'noteSpinner',
      'toggleResultTarget',
    ];
  }

  connect() {
    defer(() => {
      if (this.reloadAttendanceList) {
        this.attendanceListController.reload(undefined, true);
      }
    });

    this.updateElapsedLoop();
  }

  disconnect() {
    cancelAnimationFrame(this.updateRequest);
  }

  startedAtTargetConnected(target) {
    this.savedStartedAt = target.value;
  }

  noteTargetConnected(target) {
    this.savedNote = target.value;

    target.setSelectionRange(target.value.length, target.value.length);

    defer(() => {
      target.focus();
    });
  }

  updateElapsedLoop() {
    this.updateElapsed();
    this.updateRequest = requestAnimationFrame(this.updateElapsedLoop.bind(this));
  }

  updateElapsed() {
    if (!this.hasElapsedTarget) return;

    const start = new Date(this.elapsedTarget.dataset.start);
    const millis = new Date().getTime() - start.getTime();

    this.elapsedTarget.textContent = formatTimeString(millis / 1000, { useColons: true });
  }

  updateEventRolesFromEvent(event) {
    this.updateEventRoles(event.detail.customProperties);
  }

  updateEventRoles(props) {
    if (typeof props === 'string') {
      props = JSON.parse(props);
    }

    if (!props || Object.keys(props).length === 0) {
      return;
    }

    const { prefix, selected_event_role: selectedEventRole, event_roles: enabledEventRoles } = props;

    this.eventRoleSelectTarget.required = prefix === 'E';

    let selected;
    this.eventRoleSelectTarget.querySelectorAll('option').forEach((option) => {
      let enabled = false;

      if (prefix === 'P') {
        enabled = option.value === '';
      } else if (prefix === 'E') {
        enabled = enabledEventRoles.indexOf(parseInt(option.value, 10)) >= 0;
      }

      option.disabled = !enabled;

      if (option.selected) {
        selected = option;
      }
    });

    if (!selected || selected.disabled) {
      if (prefix === 'E') {
        if (selectedEventRole && selectedEventRole !== '') {
          this.eventRoleSelectTarget.value = selectedEventRole;
        } else if (enabledEventRoles.indexOf(4) >= 0) {
          this.eventRoleSelectTarget.value = '4';
        } else if (enabledEventRoles.indexOf(1) >= 0) {
          this.eventRoleSelectTarget.value = '1';
        } else if (enabledEventRoles.length > 0) {
          this.eventRoleSelectTarget.value = enabledEventRoles[0].toString();
        } else {
          this.eventRoleSelectTarget.value = '';
        }
      } else {
        this.eventRoleSelectTarget.value = '';
      }
    }
  }

  saveToggleWhenDocumentInvisible() {
    if (document.visibilityState === 'visible') return;

    this.saveToggle();
  }

  debouncedSaveToggle() {
    if (!this.toggleChanged) return;

    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = undefined;
    }

    if (this.saveAbortController) {
      this.saveAbortController.abort();
      this.saveAbortController = undefined;
    }

    this.saveTimeout = setTimeout(() => this.saveToggle(), 5000);
  }

  saveToggle() {
    if (!this.toggleChanged) return;

    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = undefined;
    }

    if (this.saveAbortController) {
      this.saveAbortController.abort();
      this.saveAbortController = undefined;
    }

    this.saveAbortController = new AbortController();

    const startedAt = this.hasStartedAtTarget ? this.startedAtTarget.value : undefined;
    const note = this.hasNoteTarget ? this.noteTarget.value : undefined;
    if (isVariableDefinedNotNull(startedAt) && this.savedStartedAt !== startedAt) this.showStartedAtSpinner = true;
    if (isVariableDefinedNotNull(note) && this.savedNote !== note) this.showNoteSpinner = true;

    const body = {
      open_attendance_header_dropdown: this.openAttendanceHeaderDropdownOnSaveToggle,
      toggle_result_target: this.attendanceToggleResultTargetForSaveToggle,
    };
    if (isVariableDefinedNotNull(startedAt)) body.alex_attendance_started_at = startedAt;
    if (isVariableDefinedNotNull(note)) body.alex_attendance_note = note;

    fetch(this.attendanceToggleNotePath, {
      ...this.saveRequestInit,
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error(`Response with non-200 HTTP status code.`);
        }

        window.Turbo.renderStreamMessage(await response.text());
      })
      .catch((error) => {
        if (error instanceof DOMException) {
          return;
        }

        console.warn(`Saving attendance toggle failed:`, error);

        this.showStartedAtSpinner = false;
        this.showNoteSpinner = false;
      });
  }

  get toggleChanged() {
    const startedAt = this.hasStartedAtTarget ? this.startedAtTarget.value : undefined;
    const note = this.hasNoteTarget ? this.noteTarget.value : undefined;
    return (
      (isVariableDefinedNotNull(startedAt) && this.savedStartedAt !== startedAt) ||
      (isVariableDefinedNotNull(note) && this.savedNote !== note)
    );
  }

  get saveRequestInit() {
    return {
      method: 'POST',
      credentials: 'include',
      signal: this.saveAbortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    };
  }

  get reloadAttendanceList() {
    return this.element.dataset.reloadAttendanceList === 'true';
  }

  get attendanceListController() {
    const attendanceList = document.querySelector('[data-controller="attendance-list"]');

    return this.application.getControllerForElementAndIdentifier(attendanceList, 'attendance-list');
  }

  get attendanceToggleNotePath() {
    return this.element.dataset.attendanceToggleNotePath;
  }

  set showStartedAtSpinner(value) {
    if (this.hasStartedAtSpinnerTarget) {
      this.startedAtSpinnerTarget.hidden = !value;
    }
  }

  set showNoteSpinner(value) {
    if (this.hasNoteSpinnerTarget) {
      this.noteSpinnerTarget.hidden = !value;
    }
  }

  get attendanceToggleResultTargetForSaveToggle() {
    const input = this.element.querySelector('input[name="toggle_result_target"]');
    if (!input) return '';

    return input.value;
  }

  get openAttendanceHeaderDropdownOnSaveToggle() {
    const input = this.element.querySelector('input[name="open_attendance_header_dropdown"]');
    if (!input) return false;

    return input.value === 'true';
  }
}
